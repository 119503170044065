.row2{

    padding-top:10px;
}

.email{
border: 1px #0db3c7 solid;
border-radius: 5px;
color: black;
margin-bottom:15px;
}


.input{
    border: 1px #0db3c7 solid;
    border-radius: 5px;
    margin-bottom:15px;
    height:100px;
    line-height: 20px;

}

.button{
    border: 1px #0db3c7 solid;
    border-radius: 5px;
    background-color: #0db3c7;
    color:white;
}

.form{
    display: flex;
    flex-direction: column;

}